#Header {
    display: flex;
    background-color: rgb(62, 138, 226);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 1em;
}

.Header-Left {
    float: left;
    padding: 0.5em;
}

#MidNameDisplay {
    margin: auto;
    color: white;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Header-Right {
    float: right;
    padding: 0.5em;
    padding-top: 1.25em;
}

.Header-Right button {
    margin: 0.5em;
    padding: 0.25em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: larger;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: oblique;
    transition-duration: 0.5s;
    cursor: pointer;
    background-color: white; 
    color: rgb(62, 138, 226); 
    border: 2px solid #4CAF50;
}

.Header-Right button:hover {
    background-color: #4CAF50;
    color: white;
}